import React from "react"

const IPBNosaci = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Proizvodna dužina: 6 m, 12 m. Po zahtevu moguće je isporučiti veće
          dimenzije i sečenje na meru.
        </p>
      </div>
      <div className="table-scroll">
        <table class="table is-bordered is-hoverable">
          <tbody>
            <tr className="has-background-link has-text-white">
              <td rowSpan={2}>
                <br />
                Oznaka UPN
              </td>
              <td colSpan={5}>Mere (mm)</td>
              <td rowSpan={2}>
                <br />
                Masa (kg)
              </td>
            </tr>
            <tr>
              <td>h</td>
              <td>b</td>
              <td>s</td>
              <td>t</td>
              <td>r</td>
            </tr>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>6,0</td>
              <td>10,0</td>
              <td>12,0</td>
              <td>20,40</td>
            </tr>
            <tr>
              <td>120</td>
              <td>120</td>
              <td>120</td>
              <td>6,5</td>
              <td>11,0</td>
              <td>12,0</td>
              <td>26,70</td>
            </tr>
            <tr>
              <td>140</td>
              <td>140</td>
              <td>140</td>
              <td>7,0</td>
              <td>12,0</td>
              <td>12,0</td>
              <td>33,70</td>
            </tr>
            <tr>
              <td>160</td>
              <td>160</td>
              <td>160</td>
              <td>8,0</td>
              <td>13,0</td>
              <td>15,0</td>
              <td>42,60</td>
            </tr>
            <tr>
              <td>180</td>
              <td>180</td>
              <td>180</td>
              <td>8,5</td>
              <td>14,0</td>
              <td>15,0</td>
              <td>51,20</td>
            </tr>
            <tr>
              <td>200</td>
              <td>200</td>
              <td>200</td>
              <td>9,0</td>
              <td>15,0</td>
              <td>18,0</td>
              <td>61,30</td>
            </tr>
            <tr>
              <td>220</td>
              <td>220</td>
              <td>220</td>
              <td>9,5</td>
              <td>16,0</td>
              <td>18,0</td>
              <td>71,50</td>
            </tr>
            <tr>
              <td>240</td>
              <td>240</td>
              <td>240</td>
              <td>10,0</td>
              <td>17,0</td>
              <td>21,0</td>
              <td>83,20</td>
            </tr>
            <tr>
              <td>260</td>
              <td>260</td>
              <td>260</td>
              <td>10,0</td>
              <td>17,5</td>
              <td>24,0</td>
              <td>93,0</td>
            </tr>
            <tr>
              <td>280</td>
              <td>280</td>
              <td>280</td>
              <td>10,5</td>
              <td>18,0</td>
              <td>24,0</td>
              <td>103,00</td>
            </tr>
            <tr>
              <td>320</td>
              <td>320</td>
              <td>300</td>
              <td>11,5</td>
              <td>20,5</td>
              <td>27,0</td>
              <td>127,00</td>
            </tr>
            <tr>
              <td>340</td>
              <td>340</td>
              <td>300</td>
              <td>12,0</td>
              <td>21,5</td>
              <td>27,0</td>
              <td>134,00</td>
            </tr>
            <tr>
              <td>360</td>
              <td>360</td>
              <td>300</td>
              <td>12,5</td>
              <td>22,5</td>
              <td>27,0</td>
              <td>142,00</td>
            </tr>
            <tr>
              <td>400</td>
              <td>400</td>
              <td>300</td>
              <td>13,5</td>
              <td>24,0</td>
              <td>27,0</td>
              <td>155,00</td>
            </tr>
            <tr>
              <td>450</td>
              <td>450</td>
              <td>300</td>
              <td>14,0</td>
              <td>26,0</td>
              <td>27,0</td>
              <td>171,00</td>
            </tr>
            <tr>
              <td>500</td>
              <td>500</td>
              <td>300</td>
              <td>14,5</td>
              <td>28,0</td>
              <td>27,0</td>
              <td>187,00</td>
            </tr>
            <tr>
              <td>550</td>
              <td>550</td>
              <td>300</td>
              <td>15,0</td>
              <td>29,0</td>
              <td>27,0</td>
              <td>199,00</td>
            </tr>
            <tr>
              <td>600</td>
              <td>600</td>
              <td>300</td>
              <td>15,5</td>
              <td>30,0</td>
              <td>27,0</td>
              <td>212,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IPBNosaci
